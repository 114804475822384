import './App.css';
import React, { useEffect, useState, useRef } from 'react'
import { useTheme } from '@aws-amplify/ui-react';
import { Card, Flex, Text, Heading, TextField, View, useAuthenticator, Button } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';
// ★
Amplify.configure({
	Auth: {
		region: 'ap-northeast-1',
		userPoolId: 'ap-northeast-1_WU18iBPqv',
		userPoolWebClientId: '5oeig3sppr0d6b3e1a5lr9l1da',
		identityPoolId: 'ap-northeast-1:622330c4-3f05-4b12-bb62-834a2127b524'
	}
})

// Initialize CloudWatch Logs
const cloudwatchlogs = new AWS.CloudWatchLogs({ region: 'ap-northeast-1' });

const sso_endpoint = 'https://xnx76lh201.execute-api.ap-northeast-1.amazonaws.com/prod/sso';
const main_hc_url = 'https://portal.lkdkuserportal-sandbox.bccs.staging-test.sios.jp/';
const iframe_white_list = [main_hc_url,'https://mypage.lkdkuserportal-sandbox.bccs.staging-test.sios.jp/'];
const s3_endpoint = 'https://zeq-023-sfdc-zendesk.s3.ap-northeast-1.amazonaws.com/migrated/';
const dict = {
	'ja': {
		'Enter your Username': "username@example.com",
		'Enter your Email': "username@example.com",
		'Enter your Password': " ",
		'User does not exist.': "メールアドレスまたはパスワードが正しくありません",
		'Incorrect username or password.': "メールアドレスまたはパスワードが正しくありません",
		'User is disabled.': 'ご利用頂けないユーザーアカウントです',
		'Password did not conform with policy: Password not long enough': "パスワードが短すぎます",
		'Password does not conform to policy: Password not long enough': "パスワードが短すぎます",
		'Password does not conform to policy: Password must satisfy regular expression pattern: ^\\S\.\*\\S\$': "パスワードが短すぎます",
		'Password does not conform to policy: Password must have uppercase characters': "パスワードには英大文字を含む必要があります",
		'Password does not conform to policy: Password must have lowercase characters': "パスワードには英小文字を含む必要があります",
		'Password does not conform to policy: Password must have numeric characters': "パスワードには数字を含む必要があります",
		'Invalid session for the user, session is expired.': "セッションが無効です",
		'Invalid verification code provided, please try again.': '無効な認証コードです。',
		'Invalid code provided, please request a code again.': '無効な認証コードです。',
		'Password attempts exceeded': "パスワードを一定回数以上間違えました。しばらく経ってから再度お試しください。",
		'Attempt limit exceeded, please try after some time.': "試行回数の上限を超えました。しばらく経ってから再度お試しください。",
		'Account recovery requires verified contact information': "アカウントを復旧するには連絡先の確認が必要です",
		'Back to Sign In': "サインイン画面へ戻る",
		'Change Password': "パスワード変更",
		'Change': "変更",
		'Send code': "確認コードを送信",
		'Code': "確認コード",
		'Code *': "確認コード *",
		'Confirm Password': "新しいパスワード（再入力）",
		'Please confirm your Password': " ",
		'Your passwords must match': "パスワードを一致させてください",
		'Sending': "送信中",
		'Submitting': "送信中",
		'Changing': "変更中",
		'Confirm Sign In': "確認",
		'Confirm Sign Up': "サインアップ",
		'Confirm': "確認",
		'Email': "メールアドレス",
		'Forgot your password?': "パスワードをお忘れの場合",
		'Reset Password': "サインインパスワード再設定",
		'Enter your username': "ユーザーIDを入力してください",
		'Enter your email': "メールアドレスを入力してください",
		'Loading...': "ロード中...",
		'New Password': "新しいパスワード",
		'No MFA': "MFAなし",
		'Password': "パスワード",
		'Phone Number': "電話番号",
		'Pick a File': "ファイルを選択する",
		'Resend a Code': "確認コードを再送する",
		'Resend Code': "確認コードを再送する",
		'Select MFA Type': "MFAタイプの選択",
		'Select your preferred MFA Type': "MFAタイプを選択してください",
		'Sign In Account': "サインイン",
		'Sign in': "サインイン",
		'Signing in': "サインイン中",
		'Sign Out': "サインアウト",
		'Sign Up Account': "サインアップ",
		'Sign Up': "サインアップ",
		'Skip': "スキップする",
		'Submit': "保存",
		'Username': "ユーザーID",
		'Verify Contact': "確認",
		'Verify': "確認する",
		'1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]\+\.\*[\\S]\+\$': 'パスワードの形式が不正です'
	}
};
//

I18n.putVocabularies(dict);
I18n.setLanguage('ja');

async function sha256(text) {
	const uint8 = new TextEncoder().encode(text)
	const digest = await crypto.subtle.digest('SHA-256', uint8)
	return Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2, '0')).join('')
}

/**
 * additional component
 */
const components = {
	Header() {
		const { tokens } = useTheme();
		return (
			<View textAlign="center" padding={tokens.space.large}>
				LifeKeeper/DataKeeper ユーザーポータル
				<View marginTop="var(--amplify-space-large)">ご登録のメールアドレス、パスワードを入力し、<br />サインインしてください。</View>
			</View>
		);
	},
	SignIn: {
		Footer() {
			const { toResetPassword } = useAuthenticator();
			const [isMigrated, setIsMigrated] = useState(true);
			// 移行済みチェック
			useEffect(() => {
				// SignInフォームにフックが無いので苦肉の策
				setTimeout(() => {
					const input = document.querySelector('input[name="username"]');
					if (input && !input.getAttribute('el-registered')) {
						input.setAttribute('el-registered', 'true');
						input.addEventListener('blur', async () => {
							const email = document.querySelector('input[name="username"]').value;
							if (email && email.match('@')) {
								let response = await fetch(s3_endpoint + await sha256(email));
								setIsMigrated(response.status == 200);
							}
						});
					}
				});
			}, []);
			const notice = isMigrated ? '' : (
				<View style={{ marginBottom: "20px" }}>
					<span style={{ fontWeight: "bold", marginLeft: "-5px" }}>■ご登録がない方</span><br />
					<a href="https://mk.sios.jp/userportal_application_g" target="_blank" rel="noreferrer">【こちら】</a>より申請をお願いいたします。<br /><br />
					<span style={{ fontWeight: "bold", marginLeft: "-5px" }}>■パスワードをお忘れの方</span><br />
					下記の「パスワードをお忘れの場合」より、パスワードの再設定をお願いいたします。
				</View>
			);
			return (
				<View style={{ padding: "0 30px 0 30px", fontSize: "10pt" }}>
					{notice}
					<View data-amplify-footer>
						<Button
							fontWeight="normal"
							onClick={toResetPassword}
							size="small"
							variation="link"
						>
							パスワードをお忘れの場合
						</Button>
					</View>
				</View>
			);
		},
	},
	ResetPassword: {
		Footer() {
			const { toSignIn } = useAuthenticator();
			return (<>
				<View style={{ fontSize: "10pt", color: "red" }}>
					ご登録のアドレスに以下件名のメールが送付されますので、確認コードをご入力の上、パスワードの再設定をお願いいたします。<br /><br />
					件名:【LifeKeeper/DataKeeprユーザーポータル】確認コードのご案内<br /><br />
					※ご登録のないメールアドレス、仮パスワードのお客様には確認コードはお送りされないのでご注意ください
				</View>
				<Button
					fontWeight="normal"
					onClick={toSignIn}
					size="small"
					variation="link"
					className='back-to-sign-in'
				>
					サインイン画面へ戻る
				</Button>
			</>);
		}
	},
	ForceNewPassword: {
		FormFields() {
			const { user, error } = useAuthenticator((context) => [context.user, context.error]);
			const inputRef = useRef(null);
			const confirmRef = useRef(null);
			const [isLoading, setIsLoading] = useState(false);
			const [errorMsg, setErrorMsg] = useState(false);
			const onClick = async (e) => {
				setErrorMsg('');
				const email = user.challengeParam.userAttributes.email;
				const password = inputRef.current.value;
				if (password !== confirmRef.current.value) {
					setErrorMsg('パスワードを一致させてください');
					e.preventDefault();
					return;
				} else if (password.length >= 8 && email.indexOf(password) !== -1) {
					setErrorMsg('メールアドレスに含まれる文字列は使用できません');
					e.preventDefault();
					return;
				}
				// setIsLoading(true);
				setTimeout(() => { setIsLoading(false) }, 1000); // 苦肉の策
			};
			return (
				<>
					<TextField label="新しいパスワード" name="password" type="password" ref={inputRef} isRequired={true} hasError={errorMsg} />
					<TextField label="新しいパスワード（再入力）" name="confirm_password" ref={confirmRef} type="password" isRequired={true}
						hasError={errorMsg} errorMessage={errorMsg} />
					<Button
						className='changePassword'
						variation="primary"
						type="submit"
						loadingText=""
						onClick={(e) => onClick(e)}
						isLoading={isLoading}
						rowGap="10px"
						fontWeight={"var(--amplify-font-weights-normal)"}
					>
						パスワード変更
					</Button>
				</>
			);
		}
	}
};

const formFields = {
	resetPassword: {
		username: {
			label: 'メールアドレスを入力してください',
			placeholder: 'username@example.com'
		}
	}
};


let sso_processing = false;
const ZendeskSSO = () => {
	useEffect(() => {
		!sso_processing && (async () => {
			sso_processing = true;
			let user = await Auth.currentAuthenticatedUser({
				bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
			})
			console.log('user', user);

			let response = await fetch(sso_endpoint, {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': user.signInUserSession.idToken.jwtToken
				},
				body: JSON.stringify({ url: window.location.href })
			});
			const data = await response.json()
			console.log(data);
			// window.location.href = data.redirect;
			// If we get a JWT token and a redirect URL, submit it to Zendesk using POST
			if (data.token && data.redirect) {
				const form = document.createElement('form');
				form.method = 'POST';
				form.action = data.redirect;

				const jwtInput = document.createElement('input');
				jwtInput.type = 'hidden';
				jwtInput.name = 'jwt';
				jwtInput.value = data.token;
				form.appendChild(jwtInput);

				document.body.appendChild(form);

				form.submit();

				document.body.removeChild(form);
			} else {
				console.error('JWT or redirect URL missing in the response');
			}
		})();
	}, []);
	return <>サインイン中...</>
}

const AppSignIn = () => {
	const services = {
		async handleSignIn(formData) {
		  let { username, password } = formData;
	
		  // Custom code
		  console.log('on sign in');
		  document.cookie = 'z-logged-in-once=true';
	
		  try {
			const user = await Auth.signIn(username, password);
	
			// Handle specific challenges if necessary
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
			  // Handle NEW_PASSWORD_REQUIRED if applicable
			  console.log('New password required for user:', username);
			}
	
			return user; // Login successful
		  } catch (error) {
			// Log the failure reason
			console.error('Sign-in error:', error);
	
			// Define a failure reason for CloudWatch
			let failureReason;
			switch (error.code) {
			  case 'UserNotConfirmedException':
				failureReason = 'User Not Confirmed';
				break;
			  case 'PasswordResetRequiredException':
				failureReason = 'Password Reset Required';
				break;
			  case 'NotAuthorizedException':
				failureReason = error.message.includes('Password attempts exceeded')
				  ? 'Password Attempts Exceeded'
				  : 'Incorrect Password';
				break;
			  case 'UserNotFoundException':
				failureReason = 'User Not Found';
				break;
			  case 'InvalidParameterException':
				failureReason = 'Invalid Parameters';
				break;
			  default:
				failureReason = 'Unknown Error';
			}
	
			// Log the failure event to CloudWatch
			const logParams = {
			  logGroupName: 'CognitoSignInFailures',
			  logStreamName: 'SignInFailuresStream',
			  logEvents: [
				{
				  message: JSON.stringify({
					timestamp: new Date().toISOString(),
					username: username || 'Unknown',
					failureReason: failureReason,
					errorCode: error.code,
					errorMessage: error.message,
				  }),
				  timestamp: Date.now(),
				},
			  ],
			};
	
			try {
			  await cloudwatchlogs.putLogEvents(logParams).promise();
			} catch (logError) {
			  console.error('Failed to log to CloudWatch:', logError);
			}
	
			throw error; // Re-throw the error for application-level handling
		  }
		},
		async handleForgotPasswordSubmit(formData) {
		  let { username, code, password } = formData;
		  if (password.length >= 8 && username.indexOf(password) !== -1) {
			throw new Error('メールアドレスに含まれる文字列は使用できません');
		  }
		  return Auth.forgotPasswordSubmit(username, code, password);
		},
	  };
	return (
		<Authenticator hideSignUp={true} loginMechanisms={['email']} components={components} services={services} formFields={formFields}>
			<ZendeskSSO />
		</Authenticator>
	);
}

const AppSignOut = () => {
	useEffect(() => {
		console.log('sign out');
		(async () => {
			await Auth.signOut();
			window.location.href = new URL(window.location.href).searchParams.get('return_to') || main_hc_url;
		})();
	},[]);
	return <>サインアウトしました</>
}

/**
 * Zendesk Guideのiframeから呼ばれるのでstorageのcognito認証トークンをpostMessageで親フレームに渡す 
 * */
let sentOnce = false;
const AppAuthIFrame = () => {
	useEffect(() => {
		!sentOnce && (async () => {
			const parent_origin = new URL(document.referrer).origin+'/';
			try {
				if ( iframe_white_list.indexOf(parent_origin) === -1 ){
					throw new Error('読み込みが許可されていません:'+parent_origin);
				}
				sentOnce = true;
				let user = await Auth.currentAuthenticatedUser({
					bypassCache: true
				});
				console.log({ user });
				if (user && user.signInUserSession) {
					const message = {
						idToken: user.signInUserSession.idToken.jwtToken,
						mypage_available: parseInt(user.attributes['custom:mypage_available']),
						company_name: user.attributes['custom:company_name'],
						email: user.attributes.email
					}
					window.parent.postMessage(message, parent_origin);
				}
			} catch (e) {
				// unauthorized
				console.log(e);
				window.parent.postMessage({ mypage_available: 0, logout: true }, parent_origin);
			}
		})();
	});
	return <>auth</>;
}

/**
 * プロフィール更新。Zendesk Guideのiframeから呼ばれる
 * */
const AppEdit = () => {
	const parent_origin = new URL(document.referrer).origin+'/';
	if ( iframe_white_list.indexOf(parent_origin) === -1 ){
		throw new Error('読み込みが許可されていません:'+parent_origin);
	}
	const inputRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const onClick = async (user) => {
		setIsLoading(true);
		await Auth.updateUserAttributes(user, {
			'preferred_username': inputRef.current.value
		});
		setIsLoading(false);
		window.parent.postMessage({ update: 'success' }, parent_origin);
	};

	return (
		<Authenticator hideSignUp={true} loginMechanisms={['email']} components={components}>
			{({ user }) => {
				console.log(user);
				return (
					<Card>
						<Flex
							direction="column"
							justifyContent="flex-start"
							alignItems="stretch"
							alignContent="flex-start"
							wrap="nowrap"
							gap="1rem"
						>
							<Heading
								width='30vw'
								level={5}
							>
								プロフィール変更
							</Heading>
							<TextField
								label="名前"
								defaultValue={user.attributes.preferred_username}
								errorMessage="入力必須です"
								ref={inputRef}
							/>
							<TextField
								label="メールアドレス"
								defaultValue={user.attributes.email}
								isDisabled={true}
							/>
							<Button
								variation="primary"
								loadingText=""
								onClick={() => onClick(user)}
								ariaLabel=""
								isLoading={isLoading}
								rowGap="10px"
							>
								変更する
							</Button>
							<Text>※変更後、反映されるまでしばらく時間が掛かります</Text>
						</Flex>
					</Card>
				)
			}}
		</Authenticator>
	);
};

/** メンテ画面 */
const AppMaintenance = () => {
	return <Card>
		<View padding="1rem">
			LifeKeeper/DataKeeprユーザーポータルをご利用頂きまして、誠にありがとうございます。
		</View>
		<View padding="1rem">
			この度、下記のとおりシステムメンテナンスを実施させていただいており、<br />
			現在、ユーザーポータルへのサインインができません。
		</View>
		<View padding="1rem">
			■システムメンテナンス日時<br />
			　　・2023年8月22日（火）6：00～8：00<br />
			　　　8：00以降ログイン可能の予定ですが、メンテナンス完了時間が前後する場合は、<br />
			　　　ユーザーポータルにてお知らせいたします。
		</View>
		<View padding="1rem">
			■システムメンテナンス中のご注意事項<br />
			　　・テクニカルサポートへのお問い合わせはできません。<br />
			　　・パートナー様向け限定コンテンツは閲覧できません。
		</View>
		<View padding="1rem">
			お客様には大変ご不便をおかけいたしますが、何とぞご理解を賜りますよう、よろしくお願い申し上げます。
		</View>
	</Card>
	;
};

export default () => (
	<Authenticator.Provider>
		<BrowserRouter>
			<Routes>
				<Route exact path='/' element={<AppSignIn />} />
				<Route exact path='/logout' element={<AppSignOut />} />
				<Route exact path='/auth' element={<AppAuthIFrame />} />
				<Route exact path='/edit' element={<AppEdit />} />
				<Route exact path='/maintenance' element={<AppMaintenance />} />
			</Routes>
		</BrowserRouter>
	</Authenticator.Provider>
);